









































































































































































































































































































































.card-box {
  margin-bottom: 10px;
  min-height: 170px;
  .card-item {
    border-radius: 5px;
    min-width: 30%;
    height: 100%;
    justify-content: space-between;
    cursor: pointer;
    // span {
    //   line-height: 30px;
    //   color: #fff;
    // }
    // div {
    //   justify-content: space-between;
    // }
    .card-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        span {
            font-size: 1rem;
            color:#fff;
        }
        .card-num {
               font-size: 1.5rem;
    font-weight: bolder;
        }
    }
    .card-bottom {
            min-height: 3rem;
        background: #000000;
        background-color:rgba(0,0,0,0.05);
border-radius: 0px 0px 1rem 1rem;
display: flex;
align-items: center;
justify-content: center;

span {
    opacity: 1;
    color:#fff;
    font-size: .85rem;
}
    }
  }
  & > :nth-child(1) {
      
background: #42A5F5;
border-radius: 1rem;

box-shadow: 0px 0px 20px rgba(66, 165, 245, 0.3);
    // background-image: url(../../../assets/first.png);
    // background-size: cover;
  }
  & > :nth-child(2) {
    background: #3CAFBC;
    border-radius: 1rem;
box-shadow: 0px 0px 20px rgba(60, 175, 188, 0.3);
    // background-size: cover;
  }
  & > :nth-child(3) {
    background: #5C6BC0;
    border-radius: 1rem;
box-shadow: 0px 0px 20px rgba(92, 107, 192, 0.3);
    // background-size: cover;
  }
//   & > :nth-child(4) {
//     background-image: url(../../../assets/fourth.png);
//     background-size: cover;
//   }
//   & > :nth-child(5) {
//     background-image: url(../../../assets/first.png);
//     background-size: cover;
//   }
}
h3.title {
  padding: 0.35rem;
  border: 1px solid #ccc;
  border-bottom: 0;
}
.echerts-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  .btn-box {
    align-self: flex-end;
    padding: 10px;
    margin-bottom: 10px;
    .el-button {
      &:focus {
        background-color: #5C6BE8;
        color: #fff;
        border-color: #5C6BE8;
      }
    }
    .active {
       background-color: rgb(218, 221, 250);
        color: rgb(92, 107, 232);
        border-color: rgb(218, 221, 250);
    }
  }
}
.span {
  position: absolute;
  /* left: 50%; */
  /* right: 50%; */
  text-align: center;
  width: 100%;
  top: 63%;
}
