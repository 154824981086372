.card-box {
  margin-bottom: 10px;
  min-height: 170px;
}
.card-box .card-item {
  border-radius: 5px;
  min-width: 30%;
  height: 100%;
  justify-content: space-between;
  cursor: pointer;
}
.card-box .card-item .card-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.card-box .card-item .card-top span {
  font-size: 1rem;
  color: #fff;
}
.card-box .card-item .card-top .card-num {
  font-size: 1.5rem;
  font-weight: bolder;
}
.card-box .card-item .card-bottom {
  min-height: 3rem;
  background: #000000;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-box .card-item .card-bottom span {
  opacity: 1;
  color: #fff;
  font-size: .85rem;
}
.card-box > :nth-child(1) {
  background: #42A5F5;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px rgba(66, 165, 245, 0.3);
}
.card-box > :nth-child(2) {
  background: #3CAFBC;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px rgba(60, 175, 188, 0.3);
}
.card-box > :nth-child(3) {
  background: #5C6BC0;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px rgba(92, 107, 192, 0.3);
}
h3.title {
  padding: 0.35rem;
  border: 1px solid #ccc;
  border-bottom: 0;
}
.echerts-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
}
.echerts-box .btn-box {
  align-self: flex-end;
  padding: 10px;
  margin-bottom: 10px;
}
.echerts-box .btn-box .el-button:focus {
  background-color: #5C6BE8;
  color: #fff;
  border-color: #5C6BE8;
}
.echerts-box .btn-box .active {
  background-color: #daddfa;
  color: #5c6be8;
  border-color: #daddfa;
}
.span {
  position: absolute;
  /* left: 50%; */
  /* right: 50%; */
  text-align: center;
  width: 100%;
  top: 63%;
}
