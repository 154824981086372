.firstTimeLoginRecommendation .el-dialog .shell {
  height: 100%;
}
.firstTimeLoginRecommendation .el-dialog .shell span {
  width: 100%;
}
.firstTimeLoginRecommendation .dialog1 /deep/ .el-dialog {
  height: 72%;
}
.firstTimeLoginRecommendation .dialog1 /deep/ .el-dialog__body {
  height: 82%;
}
