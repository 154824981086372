























































































.firstTimeLoginRecommendation .el-dialog .shell {
  height: 100%;
}
.firstTimeLoginRecommendation .el-dialog .shell span {
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.firstTimeLoginRecommendation .dialog1 /deep/ .el-dialog {
  height: 72%;
}
.firstTimeLoginRecommendation .dialog1 /deep/ .el-dialog__body {
  height: 82%;
}
